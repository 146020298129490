export const environment = {
  production: false,
  // API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: 'https://abbott-api-uat.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe',
  API_URl_TRACEBILITY: 'https://abbott-api-uat.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-api-uat.sttarter.com/api/v1',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/luckydrawuat',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
  LICENSEKEY:'AT7zRjJKFMGEA8TR1z0TLJ057dQ1HURsGUp+RttpbfUxQ0YixBXIEVtknsWnLj0dcRELL1VVHzfXV5LVfyLvvFx2pWmEW279YGm7Nd9DcuexdK2LhlC7goUkZhERQzzIglRur0dvsZtlVcYUPjM1ZAV5Vn0EYJ2MlymeehVoYt7EW85rHUQVVhxjQkhkdXPn3iPhTSYO63t6CeoECSIpp1oBKXTpLZa0y/8HpCaTjcoxsxe1O95P4m98NS/GlkwPCTY3+oAIkPdbEIMCcOpDKtDTLB6GsT0S/bZbr0eU2VkyghuXZ2yNVjEDDn/tCckBBX/rzPTI+IoZV5SjGSltZfRwCVWB6PAcYRTO0XvVRxWUmROk88vpnzAYJQN5soheMQcUOzu5XpeeIGXJIN9tiySjdH7CCZL/rd5y0aBDKZVALKv300Op7THDwWwsvMzJOBZMh46LgoPp+KGdlyMhWe4lMDuRo9S5PEkEg2bBwL6YdVYr9w55Fr8lpQeLNO5b/FeWwoU4EYtkJ4iNhFi5D9x5K/8skqjuDY7gfAxstxmLmytUopphyG0hFvKdf/QPW07NcRVoiqhcIHYtDUZ91QCldtDTjBRrjwzRK/JmU/jmNJkr8gd+1IKCCPMityK4SF8HtRuLz6ZFlfAi0nmOtVVtL0Ku8F+tMN4XAf68rALhGR9pRgCTrz1tko82Dopof+Tbsz1qJqhEsQXPD9/1tj1wC5/l8d1vhZOHUrrabIs0KJan8WYcJpwsbFUypDHQEVjkIU4U0wzIuPBj7wEGnxEGvVGv161yZTqan99E4bHN7OrO8MGj/QGmLBn9vQfLsqW35a4/TIJMbahKFPqyd7MMAjle5lZj0zhBohGH4noq3HibHJjnTgFNQFzKDV8/ytl0vs8g',
  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',
  ACCOUNT_PASSWORD:'Test@123',
  ACCOUNT_CONFIRM_PASSWORD:'Test@123',
  PASSWORD:'test@123',
  CONFIRM_PASSWORD:'test@123',
  BRAND: 'ABBOTTPHAARUAT',
  //BRAND: 'ABBOTTPHAARDEMO',
  DEVICE_ID: '1234551231',
  IDENTIFIER_TYPE: 'MOBILE',
  //TILL_ID: 'abbott.sg.demo.admin',
  //TILL_ID: 'abbottphdemo',
  TILL_ID: 'abbott.ph.demo.1',
  COUNTRY_CODE: 'PH-AAR',
  LANGUAGE: 'en_US',
  MAXMOBNUM: 10,
  MINMOBNUM: 8,
  COUPON_BASE_URL: 'https://gft.ph/',
  bugsnag_version: '13022024',
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"
  //   Brand Name - ABBOTTPHAARDEMO
  // Org ID - 2038
  // Cluster - APAC
  // Till ID - abbottphdemo
};


